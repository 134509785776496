import React, { useState, useEffect } from "react";
import { wrap } from "@popmotion/popcorn";
import BrowserWindow from "./BrowserWindow";
import Slider, { images } from "./Slider";
import "./index.css";
import "./browser-window.css";

let _dir = 1;

function Component() {
  const [[page, direction], setPage] = useState([0, 0]);
  const count = images.length;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (page + 1 === count) {
        _dir = -1 * _dir;
      }

      if (page === 0) {
        _dir = 1;
      }

      paginate(_dir);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [page]); // eslint-disable-line

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, count, page);

  const paginate = newDirection => {
    setPage([page + newDirection, newDirection]);
  };
  return (
    <div className="pc-container">
      <BrowserWindow address="dashboard.prezcall.com">
        <div className="pc-top-left">
          <div>
            code: <strong>4386</strong>
          </div>
          <div className="pc-flex pc-items-baseline">
            <button
              className="pc-button"
              onClick={() => paginate(-1)}
              disabled={page === 0}
            >
              prev
            </button>
            <span className="pc-mh2">
              {page + 1} / {count}
            </span>
            <button
              className="pc-button"
              onClick={() => paginate(1)}
              disabled={page + 1 === count}
            >
              next
            </button>
          </div>
        </div>
        <Slider
          page={page}
          direction={direction}
          setPage={setPage}
          imageIndex={imageIndex}
        />
        <div className="pc-mt3">
          <i>[12:34:23] Viewer joined.</i>
        </div>
      </BrowserWindow>
      <BrowserWindow className="pc-ml5" address="app.prezcall.com">
        <div className="pc-mv3 pc-viewer-slide-title">
          Meeting with <strong>John Doe</strong> from <strong>XYZ Inc.</strong>
        </div>
        <Slider
          page={page}
          direction={direction}
          setPage={setPage}
          imageIndex={imageIndex}
        />
        <div className="pc-mt3">
          <img src="https://pc-demo.surge.sh/baner.png" alt="banner" width="100%" />
        </div>
      </BrowserWindow>
    </div>
  );
}

export default Component;
