import React from "react";
import { motion, AnimatePresence } from "framer-motion";

/*
export const images = [
  require("./slides/1.png"),
  require("./slides/2.png"),
  require("./slides/3.png"),
  require("./slides/4.png"),
  require("./slides/5.png")
];
*/

export const images = [
  "https://pc-demo.surge.sh/1.png",
  "https://pc-demo.surge.sh/2.png",
  "https://pc-demo.surge.sh/3.png",
  "https://pc-demo.surge.sh/4.png",
  "https://pc-demo.surge.sh/5.png",
  "https://pc-demo.surge.sh/6.png",
  "https://pc-demo.surge.sh/7.png",
  "https://pc-demo.surge.sh/8.png"
];

const variants = {
  enter: direction => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

function Slider({ className, style, page, direction, imageIndex }) {
  return (
    <div
      className={className}
      style={{ position: "relative", height: 300, ...style }}
    >
      <AnimatePresence initial={false}>
        <motion.img
          key={page}
          style={{ position: "absolute", height: "100%" }}
          src={images[imageIndex]}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 200 },
            opacity: { duration: 0.2 }
          }}
        />
      </AnimatePresence>
    </div>
  );
}

export default Slider;
