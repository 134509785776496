import React from "react";

function BrowserWindow({
  className,
  style,
  address = "prezcall.com",
  children
}) {
  const classes = [
    "home-how_interactive__panel home-how_interactive__json",
    className
  ]
    .filter(c => !!c)
    .join(" ");

  return (
    <div className={classes} style={style}>
      <div className="controls">
        <div className="macOS">
          <div className="macOS-icon macOS-icon_close" />
          <div className="macOS-icon macOS-icon_minimize" />
          <div className="macOS-icon macOS-icon_maximize" />
        </div>
        <div className="address_bar">
          <img alt="lock" src="https://pc-demo.surge.sh/lock.svg" width={15} height={13} />
          <span className="address_input">{address}</span>
        </div>
      </div>
      <div className="main">{children}</div>
    </div>
  );
}

export default BrowserWindow;
